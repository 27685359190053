import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CityService } from '../../../services/city.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { OrganizationService } from '../../../services/organization.service';
import { SubscribeService } from '../../../services/subscribe.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-subscribe-pop-up',
  templateUrl: './subscribe-pop-up.component.html',
  styleUrl: './subscribe-pop-up.component.css',
})
export class SubscribePopUpComponent {

  loader: boolean = false;
  selectedSubject: string = 'N/A';
  showError: boolean = false;
  bttn: string = 'Submit';
  form!: FormGroup;
  isMobile: boolean = false;
  mobileString: string = 'https://www-icots1.wisegrid.net/Portals/CRC/Mobile#/page/request%20information';
  IframeString: string = 'https://www-icots1.wisegrid.net/Portals/CRC/Desktop?noheader=true#/page/request%20information';


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateIframeSource();
  }

  updateIframeSource() {
    this.isMobile = window.innerWidth < 780;
    if (this.isMobile) {
      this.IframeString = this.mobileString;
    } else {
      this.IframeString = 'https://www-icots1.wisegrid.net/Portals/CRC/Desktop?noheader=true#/page/request%20information';
    }
  }
  countries = this.cityService.getCountries();
  emailReceived: string = '';
  cities: any[] = [];
  eventCode: string = '';
  page: string = '';

  loadingCities: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<SubscribePopUpComponent>,
    public sanitizer: DomSanitizer,
    private cityService: CityService,
    private fb: FormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar, // private emailService: EmailService
    private organizationService: OrganizationService,
    private subscribeService: SubscribeService,
    private router: Router,


    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!router.url.includes('/MyIntercargo/conexa')) {
      this.eventCode = 'CP'
      this.page = 'company-brochure'
    } else {
      this.eventCode = 'BR'
      this.page = 'conexa-brochure'
    }
    this.emailReceived = data.email;
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [this.emailReceived, [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      companyName: ['', Validators.required],
      country: ['', Validators.required],
      city: [{ value: '', disabled: true }, Validators.required],
      postCode: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
    });
    this.updateIframeSource();
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.initForm();
  }
  initForm() {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [this.emailReceived, [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      companyName: ['', Validators.required],
      country: ['', Validators.required],
      city: [{ value: '', disabled: true }, Validators.required],
      postCode: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
    });

    this.form.get('country')?.valueChanges.subscribe(country => {
      if (country) {
        this.loadingCities = true;
        this.form.get('city')!.disable();


        const countryName: any = this.countries.find(c => c.code === country);
        this.fetchCities(countryName.code);
      }
      else {
        this.cities = []; // Reset cities if country is cleared
        this.form.get('city')!.setValue('');
        this.form.get('city')!.disable();
      }

    });
  }



  fetchCities(country: string): void {

    this.loader = true
    this.cityService.getCities(country).subscribe(
      (response) => {


        this.cities = response; // Adjust according to API response structure

        this.cities.unshift({ name: 'Select City', code: '', timezone: '' });
        this.loadingCities = false;
        this.form.get('city')!.enable();

        this.loader = false


      },
      (error) => {
        this.loader = false
      }
    );
  }
  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form!.valid) {
      // Handle form submission here
    } else {
      // Handle form errors or invalid submission
    }
  }
  onBtnClickForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.form.valid) {
      const formData = this.form.value;
      const cityData = formData.city;
      // Map form data to API payload
      const apiData = {
        orgName: formData.companyName.trim(),
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        phone: formData.phone.trim(),
        email: formData.email.trim(),
        address1: formData.address1.trim(),
        address2: formData.address2.trim(),
        postCode: formData.postCode.trim(),
        city: formData.city.name.trim(),  // Assuming city is handled elsewhere
        countryCode: formData.country,
        unloco: formData.city.code.trim(),  // Replace with actual property from your city select
        evnetCode: this.eventCode,
        page: this.page
      };

      this.loader = true
      this.organizationService.submitOrganizationData(apiData).subscribe(
        (response) => {
          this.loader = false
          this.dialogRef.close(this.data);
          this.router.navigate([{ outlets: { modal: 'downloadthankyou' } }])
          if (this.thankyou) {
            this.thanksPopUp.nativeElement.classList.remove('d-none');
          }
        },
        (err) => {
          this.loader = false
          this.snackBar.open(err.error.message, err.error.status, {
            duration: 8000,
            panelClass: ['snackbar-error', 'snackbar-slide-in-right'],
            horizontalPosition: 'end',
            verticalPosition: 'top'
          });
        }
      );

      // Handle form submission logic here
    } else {
    }

  }
  onSelectionChange() {
    this.showError = false;
  }
  navigate() {
    this.router.navigate([{ outlets: { modal: 'thankyou' } }]);
  }
  onCloseClick(data: any) {
    this.dialogRef.close(data);
  }
  thankyou: boolean = false;

  ngAfterViewInit() {
    this.bttn = this.data.btnTxt;
  }
  @ViewChild('thanksPopUp') thanksPopUp!: ElementRef;

  onBtnClick() {

    const fieldsToCheck = ['companyName', 'firstName', 'lastName', 'email', 'phone'];
    let anyFieldInvalid = false;

    fieldsToCheck.forEach(field => {
      const control = this.form.get(field);
      if (control && control.invalid) {
        control.markAsTouched();
        anyFieldInvalid = true;
      }
    });

    if (anyFieldInvalid) {
      return;
    }
    const formData = this.form.value;
    const subData = {
      'companyName': formData.companyName,
      'fullName': `${formData.firstName} ${formData.lastName}`,
      'email': formData.email,
      'phone': formData.phone,

    }

    this.loader = true
    this.subscribeService.submitSubscribeFrom(subData).subscribe(
      (response) => {
        const resData = response;
        this.loader = false
        // this.thankyou = true;
        this.dialogRef.close(this.data);
        this.router.navigate([{ outlets: { modal: 'thankyou' } }])


        if (this.thankyou) {
          this.thanksPopUp.nativeElement.classList.remove('d-none');
        }
      },
      (error) => {
        this.loader = false
        this.dialogRef.close(this.data);

        // this.thankyou = true;
        this.router.navigate([{ outlets: { modal: 'thankyou' } }])

        // if (this.thankyou) {
        //   this.thanksPopUp.nativeElement.classList.remove('d-none');
        // }
      }
    );
  }

}
function matdialoginject(
  MAT_DIALOG_DATA: any
): (
  target: typeof SubscribePopUpComponent,
  propertyKey: undefined,
  parameterIndex: 3
) => void {
  throw new Error('Function not implemented.');
}
